body {
    /* background: #11171d url(../images/bg-body.png); */
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: rgb(211, 211, 211);
    color: rgb(81, 88, 93);
    font-size: 16px;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Developement Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #ccc
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    padding: 5px 15px;
}

.logo .logo-img {
    height: 45px;
    display: inline-block;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.hipster {
    display: inline-block;
    width: 314px;
    height: 322px;
    background: url("../images/winkball2x.png") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .hipster {
        background: url("../images/winkball2x.png") no-repeat center top;
        background-size: contain;
    }
}

/* ==========================================================================
Generic styles
========================================================================== */
.wrapper {
  background-color: transparent;
  width: 100%;
}

.main {
  margin: 0;
}

video {
  width: 100%    !important;
  height: auto   !important;
}

.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break:break-all;
}

.dropdown-menu {
  font-size: 16px;
}

.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

.readonly {
    background-color: #eee;
    opacity: 1;
}

form .progress {
    line-height: 15px;
}

.progress {
    display: inline-block;
    width: 100%;
    border: 1px groove #114c4c;
    margin: .5em 0;
}

.progress div {
  padding: 2px;
  font-size: smaller;
  width: 0;
  background: lightblue;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
  cursor: pointer;
}

.hand {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display:inline;
    list-style:none;
    margin:0;
    margin-left:15px;
    padding:0;
    vertical-align:2px;
}

.point:last {
    margin:0 !important;
}
.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre{
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: row;
   flex-direction: row;
   -webkit-justify-content: flex-end;
   justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td verifically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}
/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    /*margin-bottom: 15px;*/
    padding-top: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
      margin-bottom: 15px;
      padding-top: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
      border-bottom: 1px solid #eee;
      margin-left: 0;
      padding-left: 180px;
    }

    .dl-horizontal.jh-entity-details dt:nth-child(1),
    .dl-horizontal.jh-entity-details dd:nth-child(2) {
      border-top: 1px solid #eee;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}
.nav-tabs {
  font-size: 18px;
}

.h5 {
  font-size: 16px;
  margin: .5em 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus {
    outline: none;
}

.container .well {
  border: none;
}

.well {
  background-color: transparent;
  color: white;
}
.img-responsive {
  width: 100%;
}
.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: rgb(228, 228, 228);
}
/* ==========================================================================
 social share panel
 ========================================================================== */
 .resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none
}

.resp-sharing-button--twitter {
  background-color: #55acee
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9
}

.resp-sharing-button--pinterest {
  background-color: #bd081c
}

.resp-sharing-button--pinterest:hover {
  background-color: #8c0615
}

.resp-sharing-button--facebook {
  background-color: #3b5998
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373
}

.resp-sharing-button--tumblr {
  background-color: #35465C
}

.resp-sharing-button--tumblr:hover {
  background-color: #222d3c
}

.resp-sharing-button--reddit {
  background-color: #5f99cf
}

.resp-sharing-button--reddit:hover {
  background-color: #3a80c1
}

.resp-sharing-button--google {
  background-color: #dd4b39
}

.resp-sharing-button--google:hover {
  background-color: #c23321
}

.resp-sharing-button--linkedin {
  background-color: #0077b5
}

.resp-sharing-button--linkedin:hover {
  background-color: #046293
}

.resp-sharing-button--email {
  background-color: #777
}

.resp-sharing-button--email:hover {
  background-color: #5e5e5e
}

.resp-sharing-button--xing {
  background-color: #1a7576
}

.resp-sharing-button--xing:hover {
  background-color: #114c4c
}

.resp-sharing-button--whatsapp {
  background-color: #25D366
}

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851
}

.resp-sharing-button--hackernews {
background-color: #FF6600
}
.resp-sharing-button--hackernews:hover, .resp-sharing-button--hackernews:focus {   background-color: #FB6200 }

.resp-sharing-button--vk {
  background-color: #507299
}

.resp-sharing-button--vk:hover {
  background-color: #43648c
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--google {
  background-color: #dd4b39;
  border-color: #dd4b39;
}

.resp-sharing-button--google:hover,
.resp-sharing-button--google:active {
  background-color: #c23321;
  border-color: #c23321;
}

.resp-sharing-button--tumblr {
  background-color: #35465C;
  border-color: #35465C;
}

.resp-sharing-button--tumblr:hover,
.resp-sharing-button--tumblr:active {
  background-color: #222d3c;
  border-color: #222d3c;
}

.resp-sharing-button--email {
  background-color: #777777;
  border-color: #777777;
}

.resp-sharing-button--email:hover,
.resp-sharing-button--email:active {
  background-color: #5e5e5e;
  border-color: #5e5e5e;
}

.resp-sharing-button--pinterest {
  background-color: #bd081c;
  border-color: #bd081c;
}

.resp-sharing-button--pinterest:hover,
.resp-sharing-button--pinterest:active {
  background-color: #8c0615;
  border-color: #8c0615;
}
.icon-list > li {
  display: inline-block;
}
/* End of Social Share panel */
.filter-search-panel {
  margin: .5em 0;
}
.filter-search-panel > div {
  margin: .5em 0;
}
.map-holder {
  width: 100%;
}
.location-btn-group .col-lg-6:first-child {
  padding-left: 0;
}
.radio label {
  display: block;
}
.category-label {
  padding-right: 1em;
}
.form-group .col-md-6 {
  padding-left: 0;
}
.form-group .col-md-6:last-child {
  padding-right: 0;
}

/* Multiselect dropwdown */
.multiselect-parent, .multiselect-parent button, .multiselect-parent ul {
  width: 100%;
}

/* Filter form */
form[name="filterForm"] label {
  margin-left: .5em;
}

form[name="filterForm"] {
  margin-top: 20px;
  margin-bottom: 10px;
}

.timeline {
  max-height: 852px;
  overflow: auto;
}

.dl-horizontal.jh-entity-details > dd:nth-of-type(odd),
.dl-horizontal.jh-entity-details > dt:nth-of-type(odd) {
    /* background-color: #333; */
    background-color: rgb(228, 228, 228);
}

.iconx2 {
  font-size: 2em;
}
.iconx3 {
  font-size: 3em;
}
.iconx4 {
  font-size: 4em;
}

.panel-primary {
  border-color: white;
}

.panel-primary > .panel-heading {
  background-color: #292F33;
  border-color: white;
}

.home-tiles .panel-primary,
.home-tiles .panel-primary > .panel-heading {
  border-radius: 0;
}

/* Makes columns of the same height on bigger screens.
On smaller screens the columns will be stack on one another
as per default behaviour */
@media (min-width: 768px) {
  .row.home-tiles {
    display: flex;
    flex-wrap: wrap;
  }
}

/*==================================================== */
/* Members software */
/*==================================================== */
.edlist {
  padding: 4px;
}

.uhelpmain {
  margin-top: 24px;
}
#laa, #se, #iv, #pv, #uv {
  display: none;
}
.mindent {
  margin-left: 12px;
}
.cwell {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  color: #333;
  margin-top: 12px;
}
.edbutt {
  display: -webkit-inline-box;
}

.thumb {
  cursor: pointer;
  border-color: #333;
  border-style: solid;
  margin-top: 4px;
  background-color: #0f4534;
  background-color: rgba(100, 100, 100, 1);
  border-radius: 50%;
  z-index: 100;
  height: 24px;
  width: 24px;
  border-width: 4px;
}
.hhead {
  margin-top: 12px;
}

/*==================================================== */
/* Social Media */
/*==================================================== */

.social .container {
  margin-top: 20px;
}
.timeline-Widget {
  border-radius: 0 !important;
}

/* Carousel Styles */
.carousel-indicators .active {
  background-color: #2980b9;
}

.carousel-inner img {
  width: 100%;
  max-height: 460px
}

.carousel-control {
  width: 0;
}

.carousel-control.left,
.carousel-control.right {
opacity: 1;
filter: alpha(opacity=100);
background-image: none;
background-repeat: no-repeat;
text-shadow: none;
}

.carousel-control.left span {
padding: 15px;
}

.carousel-control.right span {
padding: 15px;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-prev,
.carousel-control .icon-next {
position: absolute;
top: 45%;
z-index: 5;
display: inline-block;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .icon-prev {
left: 0;
}

.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next {
right: 0;
}

.carousel-control.left span,
.carousel-control.right span {
background-color: #000;
}

.carousel-control.left span:hover,
.carousel-control.right span:hover {
opacity: .7;
filter: alpha(opacity=70);
}

/* Carousel Header Styles */
.header-text {
  position: absolute;
  top: 20%;
  left: 1.8%;
  right: auto;
  width: 96.66666666666666%;
  color: #fff;
}

.header-text h2 {
  font-size: 40px;
}

.header-text h2 span {
  background-color: #2980b9;
padding: 10px;
}

.header-text h3 span {
background-color: #000;
padding: 15px;
}

.btn-min-block {
  min-width: 170px;
  line-height: 26px;
}

.btn-theme {
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  margin-right: 15px;
}

.btn-theme:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

#estimate-form .control-label {
  text-align: left;
}
#estimate-form .col-sm-3 {
  text-align: center;
}

.panel-grey {
  background-color: #2e3846;
  border: 1px solid  #fff;
  border-radius: 0;
  box-shadow: 0 1px 1px rgba(0,0,0,.05);
  margin-bottom: 20px;
  padding: 15px;
}

.list-thumbnail {
  max-width: 15em;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
  padding: 4px;
}

.table>thead>tr>th,
.table>tbody>tr>th {
  text-align: center;
}

/* Image overlay */
.img-container {
  position: relative;
  text-align: center;
  /* background-image: url(../images/spinner.svg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.img-container img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  width: auto;
  height: auto;
}
.img-overlay {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  background-color: #11171d;
}

.img-overlay.sponsored {
  background-color: green;
}

.status-btn-group .btn-info {
  min-width: 7em;
}
.status-btn-group .btn.disabled,
.status-btn-group .btn[disabled],
.status-btn-group fieldset[disabled] .btn {
  opacity: .32;
}

.bkg-darkslategray {
  background-color: darkslategray;
}

.bkg-dimgray {
  background-color: dimgray;
}

.bkg-wb-yellow {
  background-color: #fedd01;
}

.bkg-warning-yellow {
  background-color: #014801;
}


.bkg-black {
  background-color: #000;
}

.bkg-white {
  background-color: #fff !important;
}

.bkg-mediumvioletred {
  background-color: mediumvioletred;
}

/* Report Status Backgrounds */
.proposal,
.bkg-proposal {
  /* background-color: #500404; */
  background-color: rgb(219, 204, 204);
}

.in_production,
.bkg-in-production {
  background-color: #b76003;
  background-color: rgb(240, 223, 206)
}

.ready_to_film,
.bkg-ready-to-film {
  /* background-color: #ada102; */
  background-color: rgb(238, 236, 207);
}

.awating_sign_off,
.awaiting_sign_off,
.bkg-awaiting-signoff {
  /* background-color: #014801; */
  background-color: rgb(203, 217, 204);
}

.cancelled,
.bkg-cancelled {
  background-color: rgb(233, 114, 114);
}

.completed {
  background-color: rgb(180, 164, 164);
}

.panel-primary > .panel-heading,
.bkg-light-grey {
  background-color: rgb(228, 228, 228);
}

.bkg-dark-grey {
  background-color: rgb(211, 211, 211);;
}

.text-dark-grey {
  color: rgb(81, 88, 93);
}
.text-light-grey {
  color:  rgb(228, 228, 228);
}
.text-white {
  color: #fff !important;
}

.bkg-wb-red {
  background-color: rgb(222, 4, 35);
}

.bkg-edit-label {
  background-color: rgb(237, 237, 237);
}
.bkg-edit-item {
  background-color: rgb(246, 246, 246);
}

/* removes border radius */
.rounded-0 {
  border-radius: 0 !important;
}

.border-none {
  border: none;
}

/* Clamps text with fade effect instead of elipses as we need to support multiple lines */
.clamp-fade {
  position: relative;
  height: 5.4em;
  overflow: hidden;
}
.clamp-fade:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40%;
  height: 1em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(228, 228, 228, 1) 50%);
}
.h5.clamp-fade {
  height: 2em;
  margin: 0;
}

/* Tiles */
.tile {
  margin: 0 0 15px 0;
}
.tile > .tile-footer,
.tile > .tile-body {
  padding: .5em;
}
.tile-footer,
.tile-body {
  border-top: 2px solid white;
}
.tile-description {
  margin: 0.5em 0 0 0;
}
.tile-head > .img-container {
  height: calc((9 / 16) * 16vw);
  overflow-y: hidden;
}

/* home page table */
.table-home {
  height: 52em;
  overflow: auto;
}
/* utility style for min width in em units */
.mw-3-em {
  min-width: 3em;
}
.mw-4-em {
  min-width: 4em;
}
.mw-5-em {
  min-width: 5em;
}
.mw-6-em {
  min-width: 6em;
}
.mw-7-em {
  min-width: 7em;
}
.mw {
  min-width: 100%;
}
/* Margin util classes */
.mg-0 {
  margin: 0;
}
.mg-t-0 {
  margin-top: 0;
}
.mg-t-1 {
  margin-top: 1em;
}
.mg-b-1 {
  margin-bottom: 1em;
}
/* Padding util classes */
.p-1 {
  padding: 1em;
}
.p-l-1 {
  padding-left: 1em;
}
.p-r-1 {
  padding-right: 1em;
}
.p-l-h-1 {
  padding-left: .5em;
}
.p-r-h-1 {
  padding-right: .5em;
}
.p-h-1 {
  padding: .5em;
}

.modal-table-holder {
  height: 36em;
  width: 100%;
  overflow: auto;
}

.modal-header > .close {
  font-size: 1.8em;
  opacity: .4;
  margin-left: 4px;
}
.btn-group-center {
  padding: 1em;
}

.btn-group-center > button,
.modal-footer > button {
  min-width: 10em;
}

.btn-search {
  color: rgb(81, 88, 93);
  border: 1px solid #ccc;
  border-left: none;
  box-shadow: inset -1px 1px 1px rgba(0, 0, 0, .075);
}

.table-row {
  display: table;
}

.table-row > .table-cell {
  float: none;
  display: table-cell;
  vertical-align: top;
}

.section-header {
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  .table-home {
    height: 30em;
  }
  .btn-group-center > button {
    margin-bottom: 1em;
    width: 100%;
  }
  .btn-group-center > button:last-child {
    margin-bottom: 0;
  }
}

/* jhipster-needle-css-add-main JHipster will add new css style */
;